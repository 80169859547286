import React, { useEffect ,useState} from 'react';
const CountUpAnimation = ({
    
    initialValue,
    targetValue,
}) => {
    const [count, setCount] = useState(initialValue);
    const duration = 4000; // 4 seconds
 
    useEffect(() => {
        let startValue = initialValue;
        const interval = Math.floor(
            duration / (targetValue - initialValue));
 
        const counter = setInterval(() => {
            if(targetValue>100)startValue += 5;
            else if(targetValue>1000)startValue+=30;
            else startValue+=2;
            if(startValue>targetValue){startValue=targetValue;}
        
            setCount(startValue);
            if (startValue >= targetValue) {
                clearInterval(counter);
            }
        }, interval);
 
        return () => {
            clearInterval(counter);
        };
    }, [targetValue, initialValue]);
 
    return (
        
            <span className="num">{count}</span>
        
    );
};
function Arabic() {
    document.documentElement.lang = 'ar';
        document.documentElement.dir = 'rtl';
    return (
      <>
        <nav className="navbar fixed-top navbar-expand-lg ">
    <div className="container">
      
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
            <li className="nav-item lang-btn">
                <a className="nav-link" href="/ar">العربية</a>
            </li>  
            <li className="nav-item lang-btn">
                <a className="nav-link" href="/en">En</a>
            </li>  
            <li className="nav-item active">
                <a className="nav-link" href="#home">الرئيسية</a>
              </li>
          <li className="nav-item">
            <a className="nav-link" href="#about">من نحن</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#programs">برامجنا</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">تواصل معنا</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#reserve">ادفع الان</a>
          </li>
        </ul>
      </div>
      <a className="navbar-brand no-logo" href="#home">
        Dr.Assem Hassan
      </a>
    </div>
  </nav>
  <section className="carousel-section" id="home">
    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="/img-1.png" className="d-block w-100" alt="..."/>
            <div className=" carousel-info ">
                <h1>د/عاصم حسن</h1><br/>
                <p>إستشارى فى انقاص الوزن</p>
            <br/>
            <a href="#contact" className="contact-btn">تواصل معنا</a>
            <a href="#reserve" className="reserve-btn">ادفع الان</a>
              </div>
          </div>
          <div className="carousel-item ">
            <img src="/img-2.png" className="d-block w-100" alt="..."/>
            <div className=" carousel-info ">
                <h1>د/عاصم حسن</h1><br/>
                <p>إستشارى فى انقاص الوزن</p>
                <br/>
            <a href="#contact" className="contact-btn">تواصل معنا</a>
            <a href="#reserve" className="reserve-btn">ادفع الان</a>
              </div>
          </div>
          <div className="carousel-item">
            <img src="/img-3.png" className="d-block w-100" alt="..."/>
            <div className=" carousel-info ">
                <h1>د/عاصم حسن</h1><br/>
                <p>إستشارى فى انقاص وزن</p>
                <br/>
            <a href="#contact" className="contact-btn">تواصل معنا</a>
            <a href="#reserve" className="reserve-btn">ادفع الان</a>
              </div>
          </div>
        </div>
        
      </div>
  </section>
  
  <section className="about-section" id="about">
    <div className="container">
        <div className="row">
            <div className="col-12"> 
            <div className=" d-flex">
                <div className="section-title" style={{ marginLeft: '20px' }}>
                    <h2>نبذة عنى</h2>
                </div>
                <div className="section-description" style={{borderLeft: 'none', borderRight:'3px solid #f6383f', paddingRight: '20px'}}>
                    <p>إستشارى فى إنقاص الوزن</p>
                </div>
            </div>
        </div>
        </div>
        <div className="row about-row">
            <div className="col-lg-5">
                <div className="about-us-image d-flex justify-content-around">
                    <img decoding="async" src="/img-4.jpg" className="img-responsive" alt=""/>
                </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
                <div className='me-2'>
                <div className="personal-details "> 
                    <p><i className="fa-solid fa-user-doctor icon-color"></i> دكتور تغذية</p> 
                    <p><i className="fa-solid fa-utensils icon-color"></i>  إستشارى فى إنقاص الوزن</p> 
                    <p><i className="fa-solid fa-person-running icon-color"></i>  مدرب لياقة بدنية و مدرب شخصى</p> 
                    <p><i className="fa-solid fa-building-columns icon-color"></i>  اخصائى فى الطب الرياضى و التأهيل من اكاديمية البحث العلمى و التدريب بلندن - بريطانيا</p> 
                    <p><i className="fa-solid fa-medal icon-color"></i> اخصائى رياضى من الاتحاد العربى للرياضة</p> 
                    <p><i className="fa-solid fa-user-ninja icon-color"></i> مدرب فنون قتالية مختلطة</p>
                </div>
                </div>
            </div>
            
        </div>
    </div>
  </section>
  <section className="count">
    <div className="container">
        <div className="row d-flex justify-content-around">
            <div className="col-lg-4 d-flex justify-content-around">
                <div className="count-box"> 
                    <h1><CountUpAnimation
                    initialValue={0}
                    targetValue={4500}
                    />+</h1> 
                    <h3>حالةإنقاص وزن</h3>
                </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-around">
                <div className="count-box"> 
                <h1><CountUpAnimation
                    initialValue={0}
                    targetValue={9}
                    />+</h1> 
                    <h3>سنين خبرة</h3>
                </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-around">
                <div className="count-box"> 
                <h1><CountUpAnimation
                    initialValue={0}
                    targetValue={2800}
                    />+</h1> 
                    <h3>حالة ترتيب لجسم مثالى</h3>
                </div>
            </div>
        </div>
    </div>
  </section>

  <section className="program-section" id="programs">
    
    <div className="container program-title">
        <div className="row">
            <div className="col-lg-8 mx-auto">
                <div className="xs-section-heading text-center">
                    <h1> <div>البرنامج يشمل </div></h1><br/>
                </div>
            </div>
        </div>
    </div>

    <div className="section-part-white">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 mx-auto mb-5">
                <div className="xs-section-heading text-center">
                    <h1>نظام غذائى  <span style={{color: 'rgb(229, 35, 37)'}}>مخصص</span></h1>
                </div>
            </div>
        </div>
    </div>
    <div className="xs-blog-shape xs-bg-cover" data-scrollax="properties: { translateY: '-250px' }">
    </div>
    
    <div className="container ">
        <div className="xs-help xs-strength">
            <div className="row">
                <div className="col-lg-1 col-md-1"></div>
                <div className="col-lg-6 col-md-6">
                    <div className="xs-pl-15 xs-pr-15 SectionSubTitle text-end">
                        <br/><div>النظام الغذائي يخصص على الحالة الصحية والهدف للشخص إذا كان يعانى من مشكلة اياً كانت المشكلة ويخصص على الهدف إذا كان نزولا للوزن او لحياة صحية خارقة</div><p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="xs-service d-flex justify-content-around">
                        <img src="/img-5.png" alt="case"/>
                        
                    </div>
                </div>
                <div className="col-lg-1 col-md-1"></div>
            </div>
        </div>
    </div>
    </div>
    <div className="section-part-grey">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 mx-auto mb-5">
                <div className="xs-section-heading text-center">
                    <h1> البرنامج التدريبى <span style={{color: 'rgb(229, 35, 37)'}}></span></h1>
                </div>
            </div>
        </div>
    </div>

    <div className="container marginBottom ">
        <div className="xs-help xs-strength">
            <div className="row">
                <div className="col-lg-1 col-md-1"></div>
                <div className="col-lg-4 col-md-4">
                    <div className="xs-service d-flex justify-content-around">
                        <img src="/img-fitness.jpeg" alt="case"/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="xs-pl-15 xs-pr-15 SectionSubTitle text-end">
                        <br/><div>تصمم التمارين على حسب مستوى اللياقة ,وحالتك الصحية ,والهدف المراد الوصول له
                        تصمم التمارين بالبيت او بالنادى على حسب التفضيل</div>
                    </div>
                </div>
                <div className="col-lg-1 col-md-1"></div>
            </div>
        </div>
    </div>
    </div>
    <div className="section-part-white">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mx-auto mb-5">
                    <div className="xs-section-heading text-center">
                        <h1>كتاب الوصفات </h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="xs-blog-shape xs-bg-cover" data-scrollax="properties: { translateY: '-250px' }">
        </div>
        
        <div className="container marginBottom">
            <div className="xs-help xs-strength">
                <div className="row">
                    <div className="col-lg-1 col-md-1"></div>
                    <div className="col-lg-6 col-md-6">
                        <div className="xs-pl-15 xs-pr-15 SectionSubTitle text-end">
                            <br/><div>هو كتاب لوصفات الطبخ الصحية حتى تستطيعى تطبيق النظام بشكل لذيذ</div><p></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="xs-service d-flex justify-content-around">
                            <img src="/img-food-book.jpg" alt="case"/>
                            
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1"></div>
                </div>
            </div>
        </div>
        </div>
    <div className="section-part-grey">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mx-auto mb-5">
                    <div className="xs-section-heading text-center">
                        <h1> جدول مخصص للمكملات الغذائية ومتابعة</h1>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container marginBottom ">
            <div className="xs-help xs-strength">
                <div className="row">
                    <div className="col-lg-1 col-md-1"></div>
                    <div className="col-lg-4 col-md-4">
                        <div className="xs-service d-flex justify-content-around">
                            <img src="/img-vitamin.jpeg" alt="case"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 ">
                        <div className="xs-pl-15 xs-pr-15 SectionSubTitle text-end">
                            <br/><div>يتم اختيار المكملات التى يحتاجها جسدك لتحقيق الهدف سريعا ولصحة خارقة 
                            متابعة كل اسبوع او بشكل يومى  على حسب اشتراكك فى اى باقة</div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1"></div>
                </div>
            </div>
        </div>
        </div>
  </section>

  <section className="contact-section" id="contact">
    <div id="projectFacts" className="sectionClass">
        <div className="fullWidth eight columns">
            <div className="projectFactsWrap " id="zb">
                <div className="item wow fadeInUpBig animated animated" data-number="55" style={{visibility: 'visible'}}>
                    <a href="https://www.tiktok.com/@assemhassan832">
                        <i className="fa-brands fa-tiktok"></i>
                        <span></span>
                    </a>
                </div>
                <div className="item wow fadeInUpBig animated animated" data-number="359" style={{visibility: 'visible'}}>

                    <a href="https://iwtsp.com/201148514588">
                        <i className="fa-brands fa-whatsapp"></i>
                        <span></span>
                    </a>
                </div>
                <div className="item wow fadeInUpBig animated animated" data-number="246" style={{visibility: 'visible'}}>

                    <a href="https://snapchat.com/t/tJgHzWNK">
                        <i className="fa-brands fa-snapchat"></i>
                        <span></span>
                    </a>
                </div>
                <div className="item wow fadeInUpBig animated animated" data-number="246" style={{visibility: 'visible'}}>

                    <a href="https://www.instagram.com/assemhassan1/">
                        <i className="fa-brands fa-instagram"></i>
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div className="justify-content-center">
    <a className="red__button stat__button" href='#reserve'>ادفع الان</a>
    </div>
</section>

  <section className="reserve" id="reserve">
    <div className="container section-part-white">
        <h1 className='me-3'>ادفع <span style={{color: 'rgb(255, 0, 0)'}}>الان</span></h1>
        <br/><br/>
        <div className='payment'>
        <div id="paypal-container-MM3L3DM2BXATJ"></div>
        </div>
    </div>
  </section>
  
  <footer>
  <div className="footer-column colum footer-bottom">
            <h3>منصات للتواصل</h3>
            <p>
            <a href="https://www.tiktok.com/@assemhassan832" className="me-2"><i className="fa-brands fa-tiktok"></i></a>
                        <a href="https://www.instagram.com/assemhassan1/" className="me-2"><i className="fa-brands fa-instagram"></i></a>
                        <a href="https://snapchat.com/t/tJgHzWNK" className="me-2"><i className="fa-brands fa-snapchat"></i></a>
            </p>
        </div>
        <div className="footer-column colum footer-bottom">
            <h3>تواصل معنا</h3>
            <ul>
                    <li><a href="mailto:Assemhassancoach@gmail.com"><i className="fa fa-envelope"></i> AssemHassanCoach@gmail.com</a></li>
                    <li><a href="https://iwtsp.com/201148514588"><i className="fa-brands fa-whatsapp"></i> 201148514588+</a></li>
                    
                </ul>
        </div>
        
        <div className="footer-column colum footer-bottom">
            <h3>ابقى على تواصل معنا</h3>
            <a href="#about">من انا</a>
        </div>
   

  </footer>

      </>
    );
  }
  
  export default Arabic;