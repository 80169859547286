import React, { useEffect ,useState} from 'react';
import Arabic from './components/arabic';
import English from './components/english';
import './style.css';
import './js'
import { Route,Routes} from 'react-router-dom';
import { fetchCountry} from './components/getCountry';

function App() {
  const [language, setLanguage] = useState('en');
  const [country, setCountry] = useState(null);
  useEffect(() => {
    async function fetchUserCountry() {
        const countryName = await fetchCountry();
        setCountry(countryName);
        if (['Egypt', 'Saudi Arabia', 'United Arab Emirates', 'Qatar', 'Kuwait'].includes(countryName)) {
          setLanguage('ar');
        } else {
          setLanguage('en');
        }
    }
    fetchUserCountry();
    
}, []);
  console.log(country);
  return (
    <Routes>
      <Route path="/ar" element={<Arabic />} />
      <Route path="/en" element={<English />} />
      <Route path="/" element={ language=='en'? <English />:<Arabic/>} />
    </Routes>
  );
}

export default App;
