import React, { useEffect ,useState} from 'react';
const CountUpAnimation = ({
    
    initialValue,
    targetValue,
}) => {
    const [count, setCount] = useState(initialValue);
    const duration = 4000; // 4 seconds
 
    useEffect(() => {
        let startValue = initialValue;
        const interval = Math.floor(
            duration / (targetValue - initialValue));
 
        const counter = setInterval(() => {
            if(targetValue>100)startValue += 5;
            else if(targetValue>1000)startValue+=30;
            else startValue+=2;
            if(startValue>targetValue){startValue=targetValue;}
        
            setCount(startValue);
            if (startValue >= targetValue) {
                clearInterval(counter);
            }
        }, interval);
 
        return () => {
            clearInterval(counter);
        };
    }, [targetValue, initialValue]);
 
    return (
        
            <span className="num">{count}</span>
        
    );
};
function English() {
    
    document.documentElement.lang = 'en';
        document.documentElement.dir = 'ltr';
    return (
      <>
        <nav className="navbar fixed-top navbar-expand-lg ">
    <div className="container">
      <a className="navbar-brand no-logo" href="#home">
        Dr.Assem Hassan
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
            
            <li className="nav-item active">
                <a className="nav-link" href="#home">Home</a>
              </li>
          <li className="nav-item">
            <a className="nav-link" href="#about">About</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#programs">Programs</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">Contact</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#reserve">Pay Now</a>
          </li>
          <li className="nav-item lang-btn">
                <a className="nav-link" href="/en">En</a>
            </li>
          <li className="nav-item lang-btn">
                <a className="nav-link" href="/ar">العربية</a>
            </li>  
              
        </ul>
      </div>
    </div>
  </nav>
  <section className="carousel-section" id="home">
    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="/img-1.png" className="d-block w-100" alt="..."/>
            <div className=" carousel-info ">
            <h1>DR Assem Hassan</h1><br/>
            <p>Weight Loss Consultant</p>
            <br/>
            <a href="#contact" className="contact-btn">Contact</a>
            <a href="#reserve" className="reserve-btn">Pay Now</a>
              </div>
          </div>
          <div className="carousel-item ">
            <img src="/img-2.png" className="d-block w-100" alt="..."/>
            <div className=" carousel-info ">
                <h1>DR Assem Hassan</h1><br/>
                <p>Weight Loss Consultant</p>
            <a href="#contact" className="contact-btn">Contact</a>
            <a href="#reserve" className="reserve-btn">Pay Now</a>
              </div>
          </div>
          <div className="carousel-item">
            <img src="/img-3.png" className="d-block w-100" alt="..."/>
            <div className=" carousel-info ">
                <h1>DR Assem Hassan</h1><br/>
                <p>Weight Loss Consultant</p>
            <a href="#contact" className="contact-btn">Contact</a>
            <a href="#reserve" className="reserve-btn">Pay Now</a>
              </div>
          </div>
        </div>
        
      </div>
  </section>
  
  <section className="about-section" id="about">
    <div className="container">
        <div className="row">
            <div className="col-12"> 
            <div className="d-flex">
                <div className="section-title">
                    <h2>ABOUT ME</h2>
                </div>
                <div className="section-description">
                    <p>Weight Loss Consultant</p>
                </div>
            </div>
        </div>
        </div>
        <div className="row about-row">
            <div className="col-lg-5">
                <div className="about-us-image d-flex justify-content-around">
                    <img decoding="async" src="/img-4.jpg" className="img-responsive" alt=""/>
                </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
                <div className="personal-details"> 
                    <p><i className="fa-solid fa-user-doctor icon-color"></i> Nutrition Doctor</p> 
                    <p><i className="fa-solid fa-utensils icon-color"></i>  Weight loss consultant</p> 
                    <p><i className="fa-solid fa-person-running icon-color"></i>  Fitness Trainer and Personal Trainer</p> 
                    <p><i className="fa-solid fa-building-columns icon-color"></i>  Specialist in Sports Medicine and Rehabilitation from the Academy of Scientific Research and Training in London, UK</p> 
                    <p><i className="fa-solid fa-medal icon-color"></i> Sports Specialist from the Arab Union for All Sports</p> 
                    <p><i className="fa-solid fa-user-ninja icon-color"></i> Mi xed Martial Arts Trainer</p>
                </div>
            </div>
            
        </div>
    </div>
  </section>
  <section className="count">
    <div className="container">
        <div className="row d-flex justify-content-around">
            <div className="col-lg-4 d-flex justify-content-around">
                <div className="count-box"> 
                <h1><CountUpAnimation
                    initialValue={0}
                    targetValue={4500}
                    />+</h1>
                    <h3>Successful Weight Loss Cases</h3>
                </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-around">
                <div className="count-box"> 
                <h1><CountUpAnimation
                    initialValue={0}
                    targetValue={9}
                    />+</h1>
                    <h3>Years Experience</h3>
                </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-around">
                <div className="count-box"> 
                <h1><CountUpAnimation
                    initialValue={0}
                    targetValue={2800}
                    />+</h1>
                    <h3>Body Sculpting Cases</h3>
                </div>
            </div>
            
        </div>
    </div>
  </section>

  <section className="program-section" id="programs">
    
    <div className="container program-title">
        <div className="row">
            <div className="col-lg-8 mx-auto">
                <div className="xs-section-heading text-center">
                    <h1> <div>Program  <span style={{color: 'rgb(229, 35, 37)'}}> Contains </span> </div></h1><br/>
                    
                </div>
            </div>
        </div>
        
    </div>

    
    <div className="section-part-white">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 mx-auto mb-5">
                <div className="xs-section-heading text-center">
                    <h1>A Customized Diet <span style={{color: 'rgb(229, 35, 37)'}}>Plan</span></h1>
                </div>
            </div>
        </div>
    </div>
    <div className="xs-blog-shape xs-bg-cover" data-scrollax="properties: { translateY: '-250px' }">
    </div>
    
    <div className="container ">
        <div className="xs-help xs-strength">
            <div className="row">
                <div className="col-lg-1 col-md-1"></div>
                <div className="col-lg-6 col-md-6">
                    <div className="xs-pl-15 xs-pr-15 SectionSubTitle">
                        <br/><div>The diet plan is customized based on the health condition and the goal of the person. If one suffers from a problem, whatever the problem is, the plan will be tailored to the goal, whether it is weight loss or to live a healthier life.</div><p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="xs-service d-flex justify-content-around">
                        <img src="/img-5.png" alt="case"/>
                        
                    </div>
                </div>
                <div className="col-lg-1 col-md-1"></div>
            </div>
        </div>
    </div>
    </div>
    <div className="section-part-grey">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 mx-auto mb-5">
                <div className="xs-section-heading text-center">
                    <h1> The Training <span style={{color: 'rgb(229, 35, 37)'}}>Program</span></h1>
                </div>
            </div>
        </div>
    </div>

    <div className="container marginBottom ">
        <div className="xs-help xs-strength">
            <div className="row">
                <div className="col-lg-1 col-md-1"></div>
                <div className="col-lg-4 col-md-4">
                    <div className="xs-service d-flex justify-content-around">
                        <img src="/img-fitness.jpeg" alt="case"/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="xs-pl-15 xs-pr-15 SectionSubTitle">
                        <br/><div>Exercises are designed based on your fitness level, health condition, and the goal you aim to achieve. the exercises can be tailored to be done at home or at the gym according to your preference.</div>
                    </div>
                </div>
                <div className="col-lg-1 col-md-1"></div>
            </div>
        </div>
    </div>
    </div>
    <div className="section-part-white">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mx-auto mb-5">
                    <div className="xs-section-heading text-center">
                        <h1>Recipe <span style={{color: 'rgb(229, 35, 37)'}}>Book</span></h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="xs-blog-shape xs-bg-cover" data-scrollax="properties: { translateY: '-250px' }">
        </div>
        
        <div className="container marginBottom">
            <div className="xs-help xs-strength">
                <div className="row">
                    <div className="col-lg-1 col-md-1"></div>
                    <div className="col-lg-6 col-md-6">
                        <div className="xs-pl-15 xs-pr-15 SectionSubTitle">
                            <br/><div>It is a book of healthy cooking recipes so you can follow the diet in a delicious way.</div><p></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="xs-service d-flex justify-content-around">
                            <img src="/img-food-book.jpg" alt="case"/>
                            
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1"></div>
                </div>
            </div>
        </div>
        </div>
    <div className="section-part-grey">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mx-auto mb-5">
                    <div className="xs-section-heading text-center">
                        <h1> Custom schedule for nutritional supplements and  <span style={{color: 'rgb(229, 35, 37)'}}>follow-up</span></h1>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container marginBottom ">
            <div className="xs-help xs-strength">
                <div className="row">
                    <div className="col-lg-1 col-md-1"></div>
                    <div className="col-lg-4 col-md-4">
                        <div className="xs-service d-flex justify-content-around">
                            <img src="/img-vitamin.jpeg" alt="case"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="xs-pl-15 xs-pr-15 SectionSubTitle">
                            <br/><div>The supplements that your body needs to achieve the goal quickly and for burning health are selected. Follow up every week or on a daily basis, depending on your subscription to any package.</div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1"></div>
                </div>
            </div>
        </div>
        </div>
  </section>

  <section className="contact-section" id="contact">
    <div id="projectFacts" className="sectionclassName">
        <div className="fullWidth eight columns">
            <div className="projectFactsWrap " id="zb">
                <div className="item wow fadeInUpBig animated animated" data-number="55" style={{visibility: 'visible'}}>
                    <a href="https://www.tiktok.com/@assemhassan832">
                        <i className="fa-brands fa-tiktok"></i>
                        <span></span>
                    </a>
                </div>
                <div className="item wow fadeInUpBig animated animated" data-number="359" style={{visibility: 'visible'}}>

                    <a href="https://iwtsp.com/201148514588">
                        <i className="fa-brands fa-whatsapp"></i>
                        <span></span>
                    </a>
                </div>
                <div className="item wow fadeInUpBig animated animated" data-number="246" style={{visibility: 'visible'}}>

                    <a href="https://snapchat.com/t/tJgHzWNK">
                        <i className="fa-brands fa-snapchat"></i>
                        <span></span>
                    </a>
                </div>
                <div className="item wow fadeInUpBig animated animated" data-number="246" style={{visibility: 'visible'}}>

                    <a href="https://www.instagram.com/assemhassan1/">
                        <i className="fa-brands fa-instagram"></i>
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <br/>
    <br/>
    <br/>
    <div className="justify-content-center ">
        <a className="red__button stat__button " href='#reserve'>Pay Now</a>
        </div>
  </section>

  <section className="reserve" id="reserve">
    <div className="container section-part-white ">
        <h1 className='ms-3'>Pay <span style={{color: 'rgb(255, 0, 0)'}}>Now</span></h1>
        <br/><br/>
        <div className="payment ">
            <div id="paypal-container-MM3L3DM2BXATJ"></div>
          </div>    
    </div>
  </section>

  <footer>
    
  <div className="footer-column colum footer-bottom">
                <h3>Get in touch</h3>
                <ul>
                    <li><a href="#about">About us</a> </li>
                </ul>
            </div>
            <div className="footer-column colum footer-bottom">
                <h3>Contact us</h3>
                <ul>
                    <li><a href="mailto:Assemhassancoach@gmail.com"><i className="fa fa-envelope"></i> AssemHassanCoach@gmail.com</a></li>
                    <li><a href="https://iwtsp.com/201148514588"><i className="fa-brands fa-whatsapp"></i> +201148514588</a></li>
                    
                </ul>
            </div>
            <div className="footer-column colum footer-bottom">
                <h3>Social Media</h3>
                <ul>
                    <li>
                        <a href="https://www.tiktok.com/@assemhassan832" className="me-2"><i className="fa-brands fa-tiktok"></i></a>
                        <a href="https://www.instagram.com/assemhassan1/" className="me-2"><i className="fa-brands fa-instagram"></i></a>
                        <a href="https://snapchat.com/t/tJgHzWNK" className="me-2"><i className="fa-brands fa-snapchat"></i></a>
                    </li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            
        
  </footer>
      </>
    );
  }
  
  export default English;