window.addEventListener('scroll', function() {
    const navbar = document.querySelector('.navbar');
    const scrollY = window.scrollY;  // Get current scroll position

    if (scrollY > 50) {  // Adjust threshold as needed (e.g., navbar height)
        navbar.classList.add('scrolled');
    } else {
        navbar.classList.remove('scrolled');
    }
});

let valueDisplayed=document.querySelectorAll('.counter');
let interval=3000;
valueDisplayed.forEach((valueDisplay)=>{
    let startValue=0;
    let endValue=parseInt(valueDisplay.getAttribute('data-target'));
    let duration=Math.floor(interval/endValue);
    let x=2;
    if(endValue>100)x=3;
    if(endValue>1000)x=20;
    let counter= setInterval(function (){
        startValue += x;
        if(startValue>endValue){startValue=endValue;}
        valueDisplay.textContent = startValue;
        if(startValue>=endValue){
            clearInterval(counter);
        }
    },duration);
});
