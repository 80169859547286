import axios from 'axios';

const GEOLOCATION_API_URL = 'https://ipapi.co/json/';

export async function fetchCountry() {
    try {
        const response = await axios.get(GEOLOCATION_API_URL);
        console.log(response.country_name);
        return response.data.country_name; // or response.data.country_name for full name
    } catch (error) {
        console.error('Error fetching country:', error);
        return null;
    }
}